import { Modal, Button, Typography, Space } from "antd";
import { useRouter } from "next/router";
import { WORKSPACE_SETTINGS_ROUTE } from "templates/workspace-settings/workspace-settings.routes";
import { PROJECT_SETTINGS_ROUTE } from "templates/project-settings/project-settings.routes";

const { Title, Text } = Typography;

interface WorkspaceCreationModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const WorkspaceCreationModal: React.FC<WorkspaceCreationModalProps> = ({
  isOpen,
  onClose,
}) => {
  const router = useRouter();

  const handleCreateProject = () => {
    onClose();
    router.push(PROJECT_SETTINGS_ROUTE.path);
  };

  const handleCreateWorkspace = () => {
    onClose();
    router.push(WORKSPACE_SETTINGS_ROUTE.path);
  };

  return (
    <Modal
      open={isOpen}
      onCancel={onClose}
      footer={null}
      width={600}
      title={<Title level={4}>Create New Workspace or Project?</Title>}
    >
      <Space direction="vertical" size="middle" style={{ width: "100%" }}>
        <Title level={5} style={{ color: "#1890ff", margin: 0 }}>
          Important: Each workspace requires its own subscription plan
        </Title>

        <Text>
          Before creating a new workspace, please note:
        </Text>
        <ul style={{ paddingLeft: 20 }}>
          <li>
            <Text strong>• Each workspace requires its own separate subscription plan</Text>
          </li>
          <li>
            <Text>
              • If you want to collaborate on work within your current workspace,
              creating a new project might be what you need instead
            </Text>
          </li>
        </ul>

        <Space direction="vertical" size="middle" style={{ width: "100%", marginTop: 24 }}>
          <Button type="primary" block onClick={handleCreateProject}>
            Create Project Instead
          </Button>
          <Button block onClick={handleCreateWorkspace}>
            Create New Workspace
          </Button>
          <Button type="text" block onClick={onClose}>
            Cancel
          </Button>
        </Space>
      </Space>
    </Modal>
  );
}; 