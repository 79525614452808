// Redux Toolkit
import { createApi } from "@reduxjs/toolkit/query/react";
import { createSlice, isAnyOf, PayloadAction } from "@reduxjs/toolkit";
// Store utils
import { customBaseQuery } from "store/utils/custom-base-query";
import { parseError } from "store/utils/parse-error";
// Types
import { Summary, SummaryInAnyPhase, SummaryMessage } from "./types";
// Schemas
import { SummaryInAnyPhaseSchema } from "./schemas";
// Initial state
import { initialState } from "./initial-state";
// WebSocket config
import { WebSocketConnectionConfigKeys, WasClosed } from "store/middleware/websockets/types";
// Utils
import { processSummaryMessage } from "./utils/process-summary-message";

// RTK Query API slice
export const summaryApi = createApi({
  reducerPath: "summaryApi",
  baseQuery: customBaseQuery,
  endpoints: (builder) => ({
    /***** --- Generate Summary Mutation --- *****/
    generateSummary: builder.mutation<
      SummaryInAnyPhase,
      {
        syntheticUserIds?: string[];
        problemsIds?: string[];
        solutionId?: string;
        projectId?: string;
        userInterviewsIds?: string[];
        summarizationFocus?: string;
      }
    >({
      query: (body) => ({
        type: "fetch",
        url: `/summaries/generate`,
        method: "POST",
        body: {
          syntheticUserIds: body.syntheticUserIds,
          problemsIds: body.problemsIds,
          solutionId: body.solutionId,
          projectId: body.projectId,
          userInterviewsIds: body.userInterviewsIds,
          summarizationFocus: body.summarizationFocus,
        },
      }),
      async onQueryStarted(_, { queryFulfilled, dispatch }) {
        const response = await queryFulfilled;
        if (response.data) {
          dispatch(connectSummaryWS(response.data.summaryId));
        }
      },
      extraOptions: {
        dataSchema: SummaryInAnyPhaseSchema,
      },
    }),
    /***** --- Export Summary Mutation --- *****/
    exportSummary: builder.mutation<
      unknown,
      { summaryId: string; studyDescription: string; fileExtension: string }
    >({
      query: ({ summaryId, fileExtension }) => ({
        type: "fetch",
        url: `/summaries/export/${summaryId}?file_extension=${fileExtension}`,
        method: "GET",
        skipParsing: true,
        isFileDownload: true,
      }),
      async onQueryStarted({ studyDescription, fileExtension }, { queryFulfilled }) {
        const response = await queryFulfilled;

        if (response.data) {
          // Handle JSON data differently from other file types
          const blobData: string | Blob =
            fileExtension === "json"
              ? JSON.stringify(response.data, null, 2) // Pretty print JSON with 2 spaces
              : (response.data as Blob);

          const blob = new Blob([blobData], {
            type: fileExtension === "json" ? "application/json" : "application/octet-stream",
          });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = `${studyDescription}.${fileExtension}`;
          a.click();
          // Remove the link after the download starts
          setTimeout(() => {
            a.remove();
            URL.revokeObjectURL(url);
          }, 100);
        }
      },
    }),
  }),
});

// Create the regular slice
const summarySlice = createSlice({
  name: "summary",
  initialState,
  reducers: {
    /***** --- Reset Summary --- *****/
    resetSummary: () => initialState,
    /***** --- Set Summary --- *****/
    setSummary: (state, action: PayloadAction<{ summary?: Summary[] | null }>) => {
      state.data = action.payload.summary ?? undefined;
    },
    /***** --- Handle WebSocket Connection --- *****/
    connectSummaryWS: {
      prepare: (summaryId: string) => ({
        payload: {
          key: `${WebSocketConnectionConfigKeys.Summary}-${summaryId}`,
          resourceId: summaryId,
        },
      }),
      reducer: (state) => state,
    },
    /***** --- Handle WebSocket Disconnection --- *****/
    disconnectSummaryWS: {
      prepare: (summaryId: string, wasClosed?: WasClosed) => ({
        payload: {
          key: `${WebSocketConnectionConfigKeys.Summary}-${summaryId}`,
          resourceId: summaryId,
          wasClosed,
        },
      }),
      reducer: (state) => state,
    },
    /***** --- Handle Streaming Summary --- *****/
    streamSummary: (state, action: PayloadAction<{ messages: SummaryMessage[] }>) => {
      if (!state.data) return state;

      const { messages } = action.payload;
      const initialSummary = (state.data[0] && state.data[0].summary) || "";

      let summary = Array.isArray(initialSummary) ? initialSummary : [initialSummary];

      let currentSectionIndex = !summary.length ? 0 : summary.length - 1;

      messages.forEach((message) => {
        const { summedSummaryText } = processSummaryMessage(message, summary, currentSectionIndex);
        summary = summedSummaryText;
        currentSectionIndex = !summedSummaryText.length ? 0 : summedSummaryText.length - 1;
      });

      if (state.data[0]) {
        state.data[0].summary = summary;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      /***** --- Handle Loading --- *****/
      .addMatcher(
        isAnyOf(summaryApi.endpoints.generateSummary.matchPending, connectSummaryWS),
        (state) => {
          state.loading = true;
        }
      )
      .addMatcher(
        isAnyOf(summaryApi.endpoints.generateSummary.matchRejected, disconnectSummaryWS),
        (state) => {
          state.loading = false;
        }
      )
      /***** --- Handle Generate Summary Fulfilled --- *****/
      .addMatcher(summaryApi.endpoints.generateSummary.matchFulfilled, (state, action) => {
        const {
          summaryId,
          studyId,
          projectId,
          userInterviews,
          createdAt,
          saved,
          summarizationFocus,
        } = action.payload;

        state.data = [
          {
            summary: "",
            messages: undefined,
            summaryId,
            studyId,
            projectId,
            userInterviews,
            createdAt,
            saved,
            summarizationFocus,
          },
          ...(state.data ? state.data : []),
        ];
      })
      /***** --- Handle Error --- *****/
      .addMatcher(
        isAnyOf(
          summaryApi.endpoints.generateSummary.matchRejected,
          summaryApi.endpoints.exportSummary.matchRejected
        ),
        (state, action) => {
          state.error = parseError(action.error);
        }
      );
  },
});

// Export actions
export const { connectSummaryWS, disconnectSummaryWS, streamSummary, resetSummary, setSummary } =
  summarySlice.actions;

// Export hooks
export const { useGenerateSummaryMutation, useExportSummaryMutation } = summaryApi;

// Combine the reducers
export const summaryReducer = {
  [summaryApi.reducerPath]: summaryApi.reducer,
  summary: summarySlice.reducer,
};
